import React from "react";
import classes from "./FlexContainer.module.scss";
import classNames from "classnames";

type Direction = "vertical" | "horizontal";
type FlexWrap = "true" | "false";
type AlignItems = "start" | "end" | "center" | "stretch";
type FlexGrow = "true" | "false";

interface PropTypes {
  className?: string;
  direction?: Direction;
  flexWrap?: FlexWrap;
  alignItems?: AlignItems;
  flexGrow?: FlexGrow;
}

const FlexContainer: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
  direction = "vertical",
  alignItems = "center",
  flexWrap = "false",
  flexGrow = "false",
  ...props
}) => {
  const orientationStyle =
    direction === "vertical" ? classes.vertical : classes.horizontal;

  const wrap =
    direction === "horizontal" && flexWrap === "true" ? classes.flexWrap : "";

  let align;
  switch (alignItems) {
    case "center":
      align = classes.center;
      break;
    case "start":
      align = classes.start;
      break;
    case "end":
      align = classes.end;
      break;
    default:
      align = classes.stretch;
  }

  const grow =
    direction === "horizontal" && flexGrow === "true" ? classes.grow : "";

  return (
    <div
      className={classNames(
        classes.container,
        orientationStyle,
        wrap,
        align,
        grow,
        className
      )}
      {...props}
    />
  );
};

export default FlexContainer;
