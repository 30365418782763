import React, { Fragment } from "react";

import { Outlet } from "react-router-dom";

interface PropTypes {
  className?: string;
}

const RootLayout: React.FC<React.PropsWithChildren<PropTypes>> = (props) => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default RootLayout;
