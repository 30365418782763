import React from "react";
import classNames from "classnames";
import classes from "./LandingCard.module.scss";

interface PropTypes {
  className?: string;
}

const LandingCard: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
  ...props
}) => {
  return (
    <div className={classNames(classes.card, className)}>{props.children}</div>
  );
};

export default LandingCard;
