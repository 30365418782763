import React from "react";
import classNames from "classnames";
import classes from "./Home.module.scss";
import { Link } from "react-router-dom";
import { ArrowForward, LaptopChromebook } from "@mui/icons-material";

import SocialButtons from "../social/SocialButtons";
import LandingCard from "../card/landing/LandingCard";
import FlexContainer from "../hosts/flex.container/FlexContainer";

interface PropTypes {
  className?: string;
}

const Home: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
  ...props
}) => {
  return (
    <LandingCard className={classes.HomeCard}>
      <FlexContainer>
        <div className={classes.content}>
          <h3 className={classes.text}>Welcome to my website!!!</h3>
          <h3 className={classes.text}>
            The site is currently under construction.
          </h3>
          <LaptopChromebook
            className={classNames(classes.icon, classes.swing)}
          />
          <Link to="info" className={classes.link}>
            InfoPage <ArrowForward className={classes.arrow} />
          </Link>
        </div>
        <SocialButtons className={classes.socialList} />
      </FlexContainer>
    </LandingCard>
  );
};

export default Home;
