import React from "react";
import Home from "../components/app/Home";
import PageHost from "../components/hosts/page.host/PageHost";

interface PropTypes {
  className?: string;
}

const HomePage: React.FC<React.PropsWithChildren<PropTypes>> = (props) => {
  return (
    <PageHost>
      <Home />
    </PageHost>
  );
};

export default HomePage;
