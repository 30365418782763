import React from "react";
import classes from "./Info.module.scss";
import { Link } from "react-router-dom";
import { ArrowBack, DisplaySettings } from "@mui/icons-material";

import LandingCard from "../card/landing/LandingCard";
import SocialButtons from "../social/SocialButtons";
import FlexContainer from "../hosts/flex.container/FlexContainer";

interface PropTypes {
  className?: string;
}

const Info: React.FC<React.PropsWithChildren<PropTypes>> = (_) => {
  return (
    <LandingCard className={classes.InfoCard}>
      <FlexContainer>
        <div className={classes.content}>
          <h3 className={classes.text}>Info page is under construction!!!</h3>
          <DisplaySettings className={classes.icon} />
          <Link to="/" className={classes.link}>
            <ArrowBack className={classes.arrow} /> HomePage
          </Link>
        </div>
      </FlexContainer>
      <SocialButtons className={classes.socialList} />
    </LandingCard>
  );
};

export default Info;
