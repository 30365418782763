import React from "react";
import classes from "./SocialButtons.module.scss";
import classNames from "classnames";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

interface PropTypes {
  className?: string;
}

const SocialButtons: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
}) => {
  return (
    <ul className={classNames(classes.social_buttons, className)}>
      <li className={classes["social_buttons-items"]}>
        <a
          className={classes.linkedIn}
          href="https://www.linkedin.com/in/marko-pavlovic-16460666/"
          target="_blank"
          rel="noreferrer"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <LinkedInIcon className={classes.icon} />
        </a>
      </li>
      <li className={classes["social_buttons-items"]}>
        <a
          className={classes.twitter}
          href="https://twitter.com/maraspav"
          target="_blank"
          rel="noreferrer"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <TwitterIcon className={classes.icon} />
        </a>
      </li>
      <li className={classes["social_buttons-items"]}>
        <a
          className={classes.facebook}
          href="https://facebook.com/maras0510"
          target="_blank"
          rel="noreferrer"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <FacebookIcon className={classes.icon} />
        </a>
      </li>
      <li className={classes["social_buttons-items"]}>
        <a className={classes.mail} href="mailto:info@maraspav.com">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <AlternateEmailIcon className={classes.icon} />
        </a>
      </li>
    </ul>
  );
};

export default SocialButtons;
