import React from "react";
import classes from "./PageHost.module.scss";
import classNames from "classnames";
import BackgroundHost from "../background/Background";

interface PropTypes {
  className?: string;
}

const PageHost: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
  ...props
}) => {
  return (
    <BackgroundHost className={classNames(className, classes.pageHost)}>
      <div className={classes.logoContainer}>
        <h1 className={classes.text}>maraspav.com</h1>
      </div>
      <div className={classes.content}>{props.children}</div>
      <div className={classes.footer}>
        <h5
          className={classes.text}
        >{`All rights reserved ${new Date().getFullYear()}`}</h5>
      </div>
    </BackgroundHost>
  );
};

export default PageHost;
