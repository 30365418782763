import React from "react";
import Info from "../components/app/Info";
import PageHost from "../components/hosts/page.host/PageHost";

interface PropTypes {
  className?: string;
}

const InfoPage: React.FC<React.PropsWithChildren<PropTypes>> = (props) => {
  return (
    <PageHost>
      <Info />
    </PageHost>
  );
};

export default InfoPage;
