import React from "react";
import classes from "./Background.module.scss";
import classNames from "classnames";

interface PropTypes {
  className?: string;
};

const BackgroundHost: React.FC<React.PropsWithChildren<PropTypes>> = ({
  className,
  ...props
}) => {
  return (
    <div className={classNames(className, classes.background)} {...props} />
  );
};

export default BackgroundHost;
